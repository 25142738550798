import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { CssGrid } from "../components/CssGrid"
import { Hero } from "../components/Hero"
import { Breadcrumbs } from "../components/Breadcrumbs"
import { Button } from "../components/Button"
import { Content } from "../components/Content"
import { Subnavigation } from "../components/Subnavigation"
import "../templates/MediaCentre.scss"

let SUBMENU = []

const Gallery = () => (
  <StaticQuery
    query={graphql`
      query BlogAllGalleryQuery {
        allWordpressWpGallery(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              date(formatString: "DD, MMM YYYY")
              title
              slug
              featured_media {
                source_url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 512, maxHeight: 512) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        submenu: allWordpressWpApiMenusMenusItems(
          filter: { slug: { eq: "media" } }
        ) {
          edges {
            node {
              items {
                title
                url
                target
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <CssGrid className="internal-page media-centre">
          <div>
            <Hero>
              <Content
                fillBackgroundWithImage
                fillBlockWithImage
                backgroundImage="about_ovolo_banner.jpg"
              >
                <h1>Image Gallery</h1>
              </Content>
            </Hero>
            {data.submenu.edges.map(({ node }, i) => {
              SUBMENU = node.items
            })}
            <Subnavigation items={SUBMENU} />
            <Breadcrumbs categoryName="Galleries" />
            <div className="container">
              <div className="row">
                <CssGrid>
                  <div className=" col col-xs-12 -col-md-3">Filters</div>
                  <div className="col col-xs-12 col-md-9 galleries">
                    <div className="blog-grids">
                      {data.allWordpressWpGallery.edges.map(({ node }) => (
                        <div key={node.slug} className="grid">
                          <div className="entry-media">
                            {node.featured_media && (
                              <Link to={`/gallery/${node.slug}`}>
                                <Img
                                  fluid={
                                    node.featured_media.localFile
                                      .childImageSharp.fluid
                                  }
                                />
                              </Link>
                            )}
                          </div>
                          <div className="entry-body">
                            <h3>
                              <Link
                                to={`/gallery/${node.slug}`}
                                dangerouslySetInnerHTML={{ __html: node.title }}
                              />
                            </h3>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Button size="small neue green">{"Load More"}</Button>
                  </div>
                </CssGrid>
              </div>
            </div>
          </div>
        </CssGrid>
      </Layout>
    )}
  />
)

export default Gallery
